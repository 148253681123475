import CryptoJs from 'crypto-js'
import encHex from 'crypto-js/enc-hex'
import Base64 from 'crypto-js/enc-base64';
  /**
   * 用于计算文件的hash值，包括sha256值和md5值
   */
export const hashFile = (file, digestAlgorithms, callback, onloadstart, newRatio) => {
    /**
     * 使用指定的算法计算hash值
     */
    function hashFileInternal (file, alog) {
        // 指定块的大小，这里设置为20MB,可以根据实际情况进行配置
        // const chunkSize = 20 * 1024 * 1024
        const chunkSize = 20 * 1024 * 1024
        let promise = Promise.resolve()
        let count = 0
        let currentCount = 0
        let fileStatus = true
        // 使用promise来串联hash计算的顺序。因为FileReader是在事件中处理文件内容的，必须要通过某种机制来保证update的顺序是文件正确的顺序
        for (let index = 0; index < file.size; index += chunkSize) {
            count = count + 1
            console.log(123)
            promise = promise.then(() => hashBlob(file.slice(index, index + chunkSize)))
        }
        /**
         * 更新文件块的hash值
         */
        function hashBlob (blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = ({ target }) => {
                    console.log(target)
                    currentCount = currentCount + 1
                    const wordArray = CryptoJs.lib.WordArray.create(target.result)
                    // 增量更新计算结果
                    alog.update(wordArray)
                    callback && callback(parseInt((currentCount/count * 100 * (newRatio || 1)).toFixed(0)))
                    resolve()
                }
                reader.readAsArrayBuffer(blob)
                reader.onloadstart = () => {
                    console.log('onloadstart')
                    onloadstart && onloadstart({ reader })
                }
                reader.onerror = (err) => {
                    console.log('onerror')
                    fileStatus = false
                    resolve()
                }
                reader.onabort = () => {
                    console.log('onabort')
                }
            })
        }
        // 使用promise返回最终的计算结果
        return promise.then(() => {
            return fileStatus ? encHex.stringify(alog.finalize()) : null
        })
    }

    // 计算文件的hash,并使用promise返回
    return hashFileInternal(file, CryptoJs.algo[digestAlgorithms].create())
}

export const hashsFile = (file, callback) => {
    /**
    * 使用指定的算法计算hash值
    */
    function hashFileInternal (file, alog, type) {
        // 指定块的大小，这里设置为20MB,可以根据实际情况进行配置
        const chunkSize = 20 * 1024 * 1024
        let promise = Promise.resolve()
        let count = 0
        let currentCount = 0
        // 使用promise来串联hash计算的顺序。因为FileReader是在事件中处理文件内容的，必须要通过某种机制来保证update的顺序是文件正确的顺序
        for (let index = 0; index < file.size; index += chunkSize) {
            count = count + 1
            promise = promise.then(() => hashBlob(file.slice(index, index + chunkSize)))
        }
        /**
        * 更新文件块的hash值
        */
        function hashBlob (blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onload = ({ target }) => {
                    if (type) {
                        currentCount = currentCount + 1
                    }
                    const wordArray = CryptoJs.lib.WordArray.create(target.result)
                    // 增量更新计算结果
                    alog.update(wordArray)
                    if (type) {
                        callback && callback(parseInt((currentCount/count * 100).toFixed(0)))
                    }
                    resolve()
                }
                reader.readAsArrayBuffer(blob)
            
            })
            
        }
        // 使用promise返回最终的计算结果
        return promise.then(() => encHex.stringify(alog.finalize()))
    }
        // 同时计算文件的sha256和md5,并使用promise返回
        return Promise.all([hashFileInternal(file, CryptoJs.algo.SHA256.create(), 1), hashFileInternal(file, CryptoJs.algo.SHA1.create())]).then(([sha256, sha1]) => ({
        sha256,
        sha1
    }))
}

/**

* 用于计算文件的hash值，包括sha256值和md5值

*/

export const hashMd5File = (file, callback, onloadstart) => {
    /**
    * 使用指定的算法计算hash值
    */
    function hashFileInternal (file, { alog, type } ) {
    // 指定块的大小，这里设置为20MB,可以根据实际情况进行配置
        const chunkSize = 20 * 1024 * 1024
        let promise = Promise.resolve() 
        let count = 0
        let currentCount = 0
        // 使用promise来串联hash计算的顺序。因为FileReader是在事件中处理文件内容的，必须要通过某种机制来保证update的顺序是文件正确的顺序
        for (let index = 0; index < file.size; index += chunkSize) {
            count = count + 1
            promise = promise.then(() => hashBlob(file.slice(index, index + chunkSize)))
        }
    /**
    * 更新文件块的hash值
    */
    function hashBlob (blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = ({ target }) => {
                currentCount = currentCount + 1
                const wordArray = CryptoJs.lib.WordArray.create(target.result)
                // 增量更新计算结果
                alog.update(wordArray)
                callback && callback(parseInt((currentCount/count * 100).toFixed(0)))
                resolve()
            }
            reader.readAsArrayBuffer(blob)
            reader.onloadstart = () => {
                console.log('onloadstart')
                onloadstart && onloadstart({ reader })
            }
            reader.onerror = (err) => {
                console.log('onerror')
                reject(err)
            }
            reader.onabort = () => {
                console.log('onabort')
            }
        })
    }
    // 使用promise返回最终的计算结果
    return promise.then(() => {
        if (type === 1) {
            return encHex.stringify(alog.finalize())
        } else {
           return alog.finalize()
        }
    })
    }
    
    // 同时计算文件的sha256和md5,并使用promise返回
    return Promise.all([hashFileInternal(file, {alog: CryptoJs.algo.SHA256.create(), type: 1}),
        hashFileInternal(file, {alog: CryptoJs.algo.MD5.create(), type: 2})])
            .then(([sha256, md5]) => ({
            sha256,
            md5
        }))
    }