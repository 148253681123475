<template>
  <div class="wrap" ref="wrapContent">
    <template>
      <el-backtop target=".layout-main" :right="11"/>
    </template>

    <div class="page-main">
      <div class="steps-wrap">
        <div :class="$i18n.locale == 'en'?'stepsEn steps' :'steps'">
          <div :class="stepsActive==item.index?'step active' : stepsActive>item.index ?'passStep step' : 'step'"
               v-for="item in steps" :key="item.index">
            <div class="title">
              <b v-if="item.index<stepsActive"><i class="el-icon-check"></i></b>
              <b v-else>{{ item.index }}</b>
              <span>{{ item.title }}</span>
            </div>
            <div class="dividLine">
              <div :class="$i18n.locale == 'en'?'lineEn line' :'line'"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-box">
        <div class="page-one" id="upFileArea" v-show="stepsActive==1">
          <div class="upFileArea"></div>
          <el-form
              class="initForm"
              ref="form"
              label-width="180px"
              :model="form"
              @submit.native.prevent
              size="small"
          >
            <el-form-item v-show="timeData.length==0">
              <label slot="label">{{ applyTimeText[0].ProTypes }}</label>
              <div :class="$i18n.locale == 'en'?'radioEn':'radioZh'">
                <span
                    :class="item.value == form.fileTypeValue? 'myradio checkradio': 'myradio'"
                    @click="chooseType(item.value)"
                    v-for="item in fileType"
                    :key="item.title"
                >
                    {{ item.title }}
                </span>
              </div>
              <el-input v-model="form.fileTypeValue" type="hidden" v-show="false"></el-input>
            </el-form-item>
            <el-form-item v-show="timeData.length==0">
              <label slot="label">{{ applyTimeText[1].AutModes.title1 }}</label>
              <div class="radioWrap">
                <el-radio v-model="form.checkedEvidence" :label="true"><b>{{ applyTimeText[1].AutModes.title2 }}</b>
                </el-radio>
                <p><b class="red">*</b>{{ applyTimeText[1].AutModes.title4 }}</p>
              </div>
              <div class="radioWrap radioWrap2">
                <el-radio v-model="form.checkedEvidence" :label="false"><b>{{ applyTimeText[1].AutModes.title3 }}</b>
                </el-radio>
                <p><b class="red">*</b>{{ applyTimeText[1].AutModes.title5 }}</p>
              </div>
            </el-form-item>
            <el-form-item>
              <label slot="label">{{ applyTimeText[2].AddFile.title1 }}</label>
              <div class="fileTable">
                <div v-if="$i18n.locale == 'zh'">
                  <p class="fileAccount" v-if="timeData.length>0">共<b>{{ timeData.length }}</b>个文件</p>
                </div>
                <div v-if="$i18n.locale == 'en'">
                  <p class="fileAccount" v-if="timeData.length>0">{{ timestampText.totalText }} <b>{{ timeData.length }}</b></p>
                </div>
                <el-table
                    :data="timeData"
                    style="width: 100%"
                    v-show="timeData.length>0"
                    :header-cell-style="{background:'#FAFAFA',color:'#000'}"
                >
                  <el-table-column type="index" :label="applyTimeText[4].Table.title1" min-width="80"
                                   align="center"></el-table-column>
                  <el-table-column prop="opusName" :label="applyTimeText[4].Table.title2" min-width="250">
                    <template slot-scope="scope">
                      <el-tooltip
                          effect="dark"
                          :content="scope.row.opusName"
                          placement="bottom"
                      >
                        <div class="fileName">
                          <i class="el-icon-document"></i>
                          <p v-show="!scope.row.modified">{{ scope.row.opusName }}</p>
                          <template v-if="scope.row.solidifySuccess !== 1">
                            <el-input :class="!scope.row.opusNameCheck?'redColor':''" v-model="scope.row.opusNameEdit"
                                      v-show="scope.row.modified" @keyup.enter.native="subEdit(scope.$index)"/>
                            <span
                                @click="editFileName(scope.$index,scope.row.opusName)"
                                v-show="!scope.row.modified"
                            >
                                                            <i class="el-icon-edit-outline"></i>
                                                        </span>
                            <el-button class="editBtn" type="primary" icon="el-icon-check" v-show="scope.row.modified"
                                       @click="subEdit(scope.$index)"></el-button>
                          </template>
                        </div>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                  <el-table-column :label="applyTimeText[4].Table.title4" width="111" align="center">
                    <template slot-scope="scope">
                      <div class="center">
                        <el-radio
                            v-model="scope.row.isUploadFile" :label="false"
                            @change="chooseAuthentication(scope.$index,scope.row.isUploadFile)"
                            :disabled="scope.row.solidifySuccess == 1"
                        >
                        </el-radio>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="applyTimeText[4].Table.title5" width="90" align="center">
                    <template slot-scope="scope">
                      <div class="center">
                        <el-radio
                            v-model="scope.row.isUploadFile" :label="true"
                            @change="chooseAuthentication(scope.$index,scope.row.isUploadFile)"
                            :disabled="scope.row.solidifySuccess == 1"
                        >
                        </el-radio>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="fileType" :label="applyTimeText[4].Table.title3" min-width="125"
                                   align="center">
                    <template slot-scope="scope">
                      <div class="fileType" @click="editFileType(scope.$index,scope.row)">
                        <span>{{ scope.row.fileType | numToFont(that) }}</span>
                        <b v-if="scope.row.solidifySuccess !== 1">
                          <i class="el-icon-caret-bottom"></i>
                        </b>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="applyTimeText[4].Table.title6" min-width="80" align="center">
                    <template slot-scope="scope">
                      <el-tooltip effect="dark" :content="applyTimeText[4].Table.title7" placement="bottom"
                                  v-if="scope.row.solidifySuccess !== 1">
                                                <span class="delBtn">
                                                    <i class="el-icon-delete" @click="remove(scope.$index)"></i>
                                                </span>
                      </el-tooltip>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div :class="timeData.length>0?'kuang kuang2':'kuang'">
                <input type="file" class="uploadfile" ref="inputer" multiple="multiple" id="uploadAre"
                       @change="addfile(1)" style="display:none"/>
                <input type="file" ref="folder" class="uploadfiles" webkitdirectory multiple="multiple"
                       @change="addfile(2)"/>
                <div class="uploadAre" @click="chooseFileOrFolder">
                  <p class="font1"><img src="@/assets/images/addFile.png" alt=""/>{{ applyTimeText[2].AddFile.title2 }}
                  </p>
                  <!-- <p class="font2">
                      最多可支持20个文件
                  </p> -->
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <label slot="label">{{ applyTimeText[2].AddFile.title3 }}</label>
              <el-input
                  type="textarea"
                  :placeholder="applyTimeText[2].AddFile.title4"
                  v-model="form.notes"
                  show-word-limit
                  class="mytextarea"
                  maxlength="100"
              >
              </el-input>
              <p class="des">{{ applyTimeText[2].AddFile.title5 }}<span
                  @click="phoneUs">{{ applyTimeText[2].AddFile.title6 }}</span></p>
            </el-form-item>
          </el-form>
          <div class="btns">
            <span class="next" @click="next(2)">{{ applyTimeText[3].Next.title1 }}</span>
          </div>
          <div class="btnFix btns" v-show="btnFix">
            <span class="next" @click="next(2)">{{ applyTimeText[3].Next.title1 }}</span>
          </div>
        </div>
        <div class="page-two" v-show="stepsActive==2">
          <div class="title"><b></b>{{ applyTimeText[5].ApplyInfo.title1 }}</div>
          <div class="personInfo">
            <el-form ref="form" label-width="140px" label-position="left">
              <el-form-item>
                <label slot="label"><b class="red">*</b>{{ applyTimeText[5].ApplyInfo.title2 }}</label>
                <el-input
                    :disabled="attestationInfo.identificationStatus == 1 || userInfo.userType == 'AUTHORIZATION_ACCOUNT'"
                    v-model="information.applyName"
                    class="myinput"
                    :placeholder="$t('applySteps.applicantStepsText') "
                >
                </el-input>
                <span v-if="$store.state.userInfo.userInfo.userType !=='AUTHORIZATION_ACCOUNT'">
                                    <span class="goRealName" v-if="attestationInfo.identificationStatus == 2"
                                          @click="goReal">
                                        {{ applyTimeText[5].ApplyInfo.title14 }}
                                    </span>
                                    <span class="goRealName" style="color:#FF7800"
                                          v-else>  {{ applyTimeText[5].ApplyInfo.title5 }}</span>
                                </span>
              </el-form-item>
              <el-form-item>
                <label slot="label"><b class="red" v-if="showNameType!==2">*</b>
                  {{ applyTimeText[5].ApplyInfo.title3 }}</label>
                <el-select
                    :disabled="isdisabled"
                    v-model="information.applyIdType"
                    :placeholder="$t('applySteps.selectStepsText') "
                    class="myinput"
                >
                  <el-option v-for="item in papersType" :key="item.value" :label="item.name"
                             :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <label slot="label"><b class="red" v-if="showNameType!==2">*</b>{{ applyTimeText[5].ApplyInfo.title4 }}</label>
                <el-input :disabled="isdisabled" v-model="information.applyIdNumber" class="myinput"
                          :placeholder="$t('applySteps.idNumberStepsText')"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="title"><b></b>{{ applyTimeText[5].ApplyInfo.title6 }}</div>
          <div class="description">
            <el-input
                type="textarea"
                :placeholder="applyTimeText[5].ApplyInfo.title7"
                v-model="opusDescribe"
                show-word-limit
                class="mytextarea"
                maxlength="300"
            >
            </el-input>
            <p class="templatebtn">
              <span>{{ applyTimeText[5].ApplyInfo.title8 }}</span>
              <b @click="rousedDialog">{{ applyTimeText[5].ApplyInfo.title9 }}</b>
            </p>
          </div>
          <div class="btns">
            <span class="prev" @click="next(1)">{{ $t('applySteps.backText') }}</span>
            <span class="next" @click="next(3)">{{ applyTimeText[3].Next.title1 }}</span>
          </div>
          <div class="btnFix btns" v-show="btnFix">
            <span class="prev" @click="next(1)">{{ $t('applySteps.backText') }}</span>
            <span class="next" @click="next(3)">{{ applyTimeText[3].Next.title1 }}</span>
          </div>
        </div>
        <div class="page-three" v-show="stepsActive==3">
          <div class="title"><b></b>{{ applyTimeText[5].ApplyInfo.title11 }}</div>
          <div class="personInfo">
            <dl>
              <dt>{{ applyTimeText[5].ApplyInfo.title2 }}：</dt>
              <dd>{{ information.applyName }}</dd>
            </dl>
            <dl>
              <dt>{{ applyTimeText[5].ApplyInfo.title3 }}：</dt>
              <dd>{{ applyIdType }}</dd>
            </dl>
            <dl>
              <dt>{{ applyTimeText[5].ApplyInfo.title4 }}：</dt>
              <dd>{{ information.applyIdNumber }}</dd>
            </dl>
            <dl>
              <dt>{{ applyTimeText[5].ApplyInfo.title6 }}：</dt>
              <dd>{{ opusDescribe }}</dd>
            </dl>
          </div>
          <div class="title"><b></b>{{ applyTimeText[5].ApplyInfo.title12 }}</div>
          <div class="fileTable">
            <el-table
                :data="timeData"
                style="width: 100%"
                :header-cell-style="{background:'#FAFAFA',color:'#000'}"
            >
              <el-table-column type="index" :label="applyTimeText[4].Table.title1" min-width="80"
                               align="center"></el-table-column>
              <el-table-column prop="opusName" :label="applyTimeText[4].Table.title2" min-width="250">
                <template slot-scope="scope">
                  <el-tooltip
                      effect="dark"
                      :content="scope.row.opusName"
                      placement="bottom"
                  >
                    <div class="fileName">
                      <i class="el-icon-document"></i>
                      <p>{{ scope.row.opusName }}</p>
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column :label="applyTimeText[4].Table.title4" min-width="70" align="center">
                <template slot-scope="scope">
                  <div class="center">
                    <el-radio v-model="scope.row.isUploadFile" :label="false"
                              :disabled="scope.row.isUploadFile==false?false:true"></el-radio>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :label="applyTimeText[4].Table.title5" min-width="70" align="center">
                <template slot-scope="scope">
                  <div class="center">
                    <el-radio v-model="scope.row.isUploadFile" :label="true"
                              :disabled="scope.row.isUploadFile==true?false:true"></el-radio>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="fileType" :label="applyTimeText[4].Table.title3" min-width="120" align="center">
                <template slot-scope="scope">
                  <div class="fileType">
                    <span>{{ scope.row.fileType | numToFont(that) }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="storeCount" :label="applyTimeText[4].Table.title8" width="135" align="center">
                <template slot-scope="scope">
                  <el-tooltip effect="dark" placement="bottom">
                    <div slot="content">
                      {{ timestampText.costText1 }}{{ tsCount + scope.row.storeCount }} {{
                        timestampText.timestamp
                      }}：<br/>
                      <span v-if="scope.row.isUploadFile">
                            {{ timestampText.costText2 }}({{ scope.row.fileSize }})
                            <span v-if="scope.row.storeCount>0">  
                                {{ timestampText.consumesText }}{{scope.row.storeCount}} {{ timestampText.timestamp }}
                            </span>
                            <span v-else> {{ timestampText.costText3 }}</span>
                      </span>
                      <br/>
                      {{ timestampText.costText4 }}{{ tsCount }} {{ timestampText.timestamp }}；<br/>
                    </div>
                    <span>{{ scope.row.storeCount + tsCount }}{{ timestampText.timestamp }}</span>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <div class="rules">
              <div class="ts">
                <span>{{timestampText.billingRules}}</span>
                <ul>
                  <li>
                    <b>1.</b>
                    <p>
                      {{ timestampText.billingRules1 }}
                      <a href="/time/Rules" target="_blank">{{timestampText.billingRules2}}</a> 
                      {{ timestampText.billingRules3 }}
                    </p>
                  </li>
                  <li><b>2.</b><p>{{timestampText.billingRules4}}</p></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="notes" v-show="this.form.notes">{{ applyTimeText[2].AddFile.title3 }}：{{ this.form.notes }}</div>
          <div class="btns" style="margin-top:30px">
            <span class="prev" @click="next(1)">{{ applyTimeText[3].Next.title3 }}</span>
            <span class="next" @click="submit">{{ applyTimeText[3].Next.title2 }}</span>
          </div>
          <div class="btnFix btns" v-show="btnFix" style="margin-top:30px">
            <span class="prev" @click="next(1)">{{ applyTimeText[3].Next.title3 }}</span>
            <span class="next" @click="submit">{{ applyTimeText[3].Next.title2 }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('dialogApplyTimeText.selectDia.info')" :visible.sync="fileOrFolder" width="480px"
               :class="form.checkedEvidence?'fileOrFolder':'fileOrFolder fileOrFolder2'" :close-on-click-modal="false"
               :close-on-press-escape="false">
      <div class="dialog_cnt">
        <div class="choose-btns">
          <span class="file" @click="choosefile">{{ $t('dialogApplyTimeText.selectDia.btn1') }}</span>
          <span class="files" @click="choosefilesss">{{ $t('dialogApplyTimeText.selectDia.btn2') }}</span>
        </div>
        <div class="tips" v-if="!form.checkedEvidence">
          <p>{{ $t('dialogApplyTimeText.selectDia.tip') }}</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="$t('dialogApplyTimeText.selectDia.info1')" :visible.sync="fileTypeBox" width="550px"
               class="chooseFileType"
               :close-on-click-modal="false" :close-on-press-escape="false">
      <div class="dialog_cnt">
                <span
                    :class="item.value == currentFileTypeValue? 'myradio checkradio': 'myradio'"
                    @click="chooseBoxType(item.value)"
                    v-for="item in fileType"
                    :key="item.title"
                >
                    {{ item.title }}
                </span>
      </div>
      <div slot="footer" class="dialog_footer">
        <el-button type="info" plain @click="fileTypeBox=false">{{ $t('dialogApplyTimeText.confirmCancel.Cancel') }}
        </el-button>
        <el-button class="copybtn" type="primary" @click="subFileType">
          {{ $t('dialogApplyTimeText.confirmCancel.Confirm') }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog :title="$t('dialogApplyTimeText.templates.title')" :visible.sync="template" width="40%"
               :modal-append-to-body="false" top="6vh"
               class="tem-dialog">
      <ul>
        <li class="tem-li">
          <h3>{{ $t('dialogApplyTimeText.templates.title1') }}</h3>
          <div class="tem-cnt">
            <p>
              {{ $t('dialogApplyTimeText.templates.input1') }}
              <span
                  @click="copy"
                  class="copybtn"
                  :data-clipboard-text="$t('dialogApplyTimeText.templates.input1')"
              >
                                <i class="el-icon-document-copy"></i>
                            </span>
            </p>
          </div>
        </li>
        <li class="tem-li">
          <h3>{{ $t('dialogApplyTimeText.templates.title2') }}</h3>
          <div class="tem-cnt">
            <p>
              {{ $t('dialogApplyTimeText.templates.input2') }} <span
                @click="copy"
                class="copybtn"
                :data-clipboard-text="$t('dialogApplyTimeText.templates.input2')"
            >
                                <i class="el-icon-document-copy"></i>
                            </span>
            </p>
          </div>
        </li>
        <li class="tem-li">
          <h3>{{ $t('dialogApplyTimeText.templates.title3') }}</h3>
          <div class="tem-cnt">
            <p>
              {{ $t('dialogApplyTimeText.templates.input3') }}
              <span
                  @click="copy"
                  class="copybtn"
                  :data-clipboard-text="$t('dialogApplyTimeText.templates.input3')"
              >
                                <i class="el-icon-document-copy"></i>
                            </span>
            </p>
          </div>
        </li>
        <li class="ts tem-ts">
          {{ $t('dialogApplyTimeText.templates.bot1') }}
        </li>
      </ul>
    </el-dialog>
    <!-- 固化弹窗 -->
    <TsaProgressPopUp
        :title="uploadingText.Applying"
        :confirmText="$t('dialogApplyTimeText.confirmCancel.Confirm')"
        :cancelText="$t('dialogApplyTimeText.confirmCancel.Cancel')"
        ref="solidifyProgressPopUp"
        v-model="opusFilePopup"
        :fileStatus="{
                waiting: uploadingText.title1,
                processing:  uploadingText.title2,
                success:uploadingText.title3,
                fail:uploadingText.title4,
                showResult:uploadingText.title5,
                fileDamage: uploadingText.title6
            }"
        percentage="solidifyPercentage"
        success="solidifySuccess"
        :width="620"
        :listData="timeData"
        :uploadSuccessNumber="opusFileUploadSuccessNumber"
        :AllUploadNumber="opusFileAllUploadNumber"
        @on-stop="opusFilePopupStop"
    />
    <TsaDialog class="ProgressPopupCancel"
               v-model="opusFileProgressPopupCancelShow"
               :width="480"
               :confirmText="$t('dialogApplyTimeText.confirmCancel.Confirm')"
               :cancelText="$t('dialogApplyTimeText.confirmCancel.Cancel')"
               @on-confirm="opusFileProgressPopupConfirm"
               @on-cancel="opusFileProgressPopupCancel"
    >
      <div class="ProgressPopupCancel-container">
        <div class="title-container">
          <h1>{{ timestampText.tipsText }}</h1>
          <img src="@/assets/images/close.png" @click="opusFileProgressPopupCancel" alt="">
        </div>
        <span class="el-icon-warning"></span>
        <h2>{{ browserText.info1 }}</h2>
      </div>
    </TsaDialog>
    <TsaProgressPopUp
        :title="uploadingText.title7"
        :confirmText="$t('dialogApplyTimeText.confirmCancel.Confirm')"
        :cancelText="$t('dialogApplyTimeText.confirmCancel.Cancel')"
        ref="ossSliceUploadProgressPopUp"
        v-model="ossUploadPopup"
        :isUpFile="true"
        :fileStatus="{
                waiting: uploadingText.title8,
                processing: uploadingText.title9,
                success:uploadingText.title10,
                fail: uploadingText.title11,
                showResult: uploadingText.title12
            }"
        percentage="ossSliceUploadPercentage"
        success="ossSliceUploadSuccess"
        :width="620"
        :listData="timeData"
        :uploadSuccessNumber="ossSliceUploadSuccessNumber"
        :AllUploadNumber="ossSliceAllUploadNumber"
        @on-stop="ossUploadPopupStop"
    />
    <TsaDialog class="ProgressPopupCancel"
               v-model="ossUploadProgressPopupCancelShow"
               :width="480"
               :disabled="ossUploadProgressPopupCancelDisabled"
               :confirmText="$t('dialogApplyTimeText.confirmCancel.Confirm')"
               :cancelText="$t('dialogApplyTimeText.confirmCancel.Cancel')"
               @on-confirm="ossUploadProgressPopupConfirm"
               @on-cancel="ossUploadProgressPopupCancel">
      <div class="ProgressPopupCancel-container">
        <div class="title-container">
          <h1>{{ timestampText.tipsText }}</h1>
          <img src="@/assets/images/close.png" @click="ossUploadProgressPopupCancel" alt="">
        </div>
        <span class="el-icon-warning"></span>
        <h2>{{ browserText.info2 }}</h2>
      </div>
    </TsaDialog>
  </div>
</template>

<script>
import {fileType, filterParams, generateUUID,fileTypeEn} from '@/utils/filter'
import {ossMultipartUpload, ossAbortMultipartUpload} from '@/utils/ossMultipartUpload'
import {hashFile, hashMd5File} from '@/utils/fileHash'
import {encrypt} from '@/utils/aes.js'
import {mapState} from 'vuex'
import {
  opusUserInfo,
  opusUpdateUserInfo,
  opusApply,
  findBalance,
  me,
  user,
  generateStsUploadSignMessage,
  completeStatus,
  lengthCount,
  cancelStsUpload
} from '@/api/service'
import {ExcludeRequest400} from '@/api/index.js'
import TsaDialog from '@/components/ApplyTime/TsaDialog'
import TsaProgressPopUp from '@/components/ApplyTime/TsaProgressPopUp'

var interval = null;
export default {
  data() {
    return {
      stepsActive: 1,
      btnFix: false,
      // ****************第一步*************
      form: {
        fileTypeValue: '',
        checkedEvidence: true,
        notes: ''
      },
      fileTypeValue: '',//文件类型值
      fil: [],//上传的文件信息
      timeData: [],//最后提交所有文件list
      fileTypeBox: false,//更改文件类型弹框
      currentFileTypeValue: '',//当前更改文件类型弹框内高亮的文件类型
      currentFileTypeIndex: '',//当前更改文件类型的索引
      fileOrFolder: false,
      // ****************第二步***************
      information: {
        applyName: '',
        applyIdType: '',
        applyIdNumber: '',
      },
      opusDescribe: '',//自述声明
      showNameType: '',//当前子账号被父账号设置的显示方式类型
      attestationInfo: {}, //认证信息
      template: false,//自诉模板
      // ****************第三步***************
      serialNos: [],//固化文件的标识，成功页用到
      currentIndex: 0,//当前计算hash的索引
      opusFilePopup: false, // 固化文件进度弹窗
      ossUploadPopup: false, // 存证 上传到oss的进度弹窗
      opusFileProgressPopupCancelShow: false,
      ossUploadProgressPopupCancelShow: false,
      ossUploadProgressPopupCancelDisabled: false,
      allowSolidification: true,//是否允许固化
      allowExistingEvidence: true,//是否允许存证
      tsCount: 1,
      TotalTimestampsCount: 0,
      that:this,
    };
  },

  components: {TsaDialog, TsaProgressPopUp},

  computed: {
    steps() {
      return [
        {
          index: 1,
          title: this.$t('applyTime.steps.title1'),
        },
        {
          index: 2,
          title: this.$t('applyTime.steps.title2'),
        },
        {
          index: 3,
          title: this.$t('applyTime.steps.title3'),
        },
      ]
    },
    fileType() {
      return [
        {
          title: this.$t('applyTime.fileType.title1'),
          value: 109,
        },
        {
          title: this.$t('applyTime.fileType.title2'),

          value: 119,
        },
        {
          title: this.$t('applyTime.fileType.title3'),

          value: 108,
        },
        {
          title: this.$t('applyTime.fileType.title4'),

          value: 120,
        },
        {
          title: this.$t('applyTime.fileType.title5'),

          value: 121,
        },
        {
          title: this.$t('applyTime.fileType.title6'),

          value: 122,
        },
        {
          title: this.$t('applyTime.fileType.title7'),

          value: 123,
        },
        {
          title: this.$t('applyTime.fileType.title8'),

          value: 124,
        },
        {
          title: this.$t('applyTime.fileType.title9'),

          value: 101,
        },
        {
          title: this.$t('applyTime.fileType.title10'),

          value: 112,
        },
        {
          title: this.$t('applyTime.fileType.title11'),

          value: 110,
        },
        {
          title: this.$t('applyTime.fileType.title12'),

          value: 104,
        },
        {
          title: this.$t('applyTime.fileType.title13'),

          value: 102,
        },
        {
          title: this.$t('applyTime.fileType.title14'),

          value: 105,
        },
        {
          title: this.$t('applyTime.fileType.title15'),

          value: 125,
        },
        {
          title: this.$t('applyTime.fileType.title16'),

          value: 999,
        },
      ]
    },
    papersType() {
      return [
        {
          name: this.$t('applySteps.idCard1'),
          value: 101,
        },
        {
          name: this.$t('applySteps.idCard2'),
          value: 102,
        },
        {
          name: this.$t('applySteps.idCard3'),

          value: 103,
        },
        {
          name: this.$t('applySteps.idCard4'),

          value: 104,
        },
        {
          name: this.$t('applySteps.idCard5'),

          value: 110,
        },
        {
          name: this.$t('applySteps.idCard6'),

          value: 111,
        },
        {
          name: this.$t('applySteps.idCard7'),

          value: 112,
        },
        {
          name: this.$t('applySteps.idCard8'),

          value: 109,
        },
        {
          name: this.$t('applySteps.idCard9'),

          value: 113,
        },
      ]
    },
    applyTimeText() {
      return [
        {
          ProTypes: this.$t('applyTime.ProTypes.title1'),
        },
        {
          AutModes: this.$t('applyTime.AutModes'),
        },
        {
          AddFile: this.$t('applyTime.AddFile'),
        },
        {
          Next: this.$t('applyTime.Next'),
        },
        {
          Table: this.$t('applyTime.Table'),
        },
        {
          ApplyInfo: this.$t('applyTime.ApplyInfo'),
        },
      ]
    },
    applyTimeToast() {
      return this.$t('applyTimeToast')
    },
    confirmCancelToast() {
      return this.$t('confirmCancel')
    },
    balanceTextToast() {
      return this.$t('balanceText')
    },
    timestampText() {
      return this.$t('timestampText')
    },
    uploadingText() {
      return this.$t('uploadingText')
    },
    browserText() {
      return this.$t('browserText')
    },
    ...mapState({}),
    userInfo: {
      get() {
        return this.$store.state.userInfo.userInfo
      },
      set(val) {
        this.$store.state.userInfo.userInfo = val
      },
      access_token() {
        return this.$store.state.userInfo.me.accessToken
      }
    },
    applyIdType() {
      switch (this.information.applyIdType) {
        case 101:
          return this.$t('applySteps.idCard1')
          break
        case 102:
          return this.$t('applySteps.idCard2')
          break
        case 103:
          return this.$t('applySteps.idCard3')
          break
        case 104:
          return this.$t('applySteps.idCard4')
          break
        case 110:
          return this.$t('applySteps.idCard5')
          break
        case 111:
          return this.$t('applySteps.idCard6')
          break
        case 109:
          return this.$t('applySteps.idCard8')
          break
        case 112:
          return this.$t('applySteps.idCard7')
          break
        case 113:
          return this.$t('applySteps.idCard9')
          break
      }
    },
    isdisabled() {
      if (this.userInfo.userType == 'AUTHORIZATION_ACCOUNT') {
        if (this.attestationInfo.identificationStatus == 1) {
          if (this.showNameType == 2) {
            return false
          } else {
            return true
          }
        } else {
          return true;
        }
      } else {
        if (this.attestationInfo.identificationStatus == 1) {
          return true;
        }
        return false;
      }
    },
    access_token() {
      return this.$store.state.userInfo.me.accessToken
    },
    // 固化时候的成功的数量
    opusFileUploadSuccessNumber() {
      let array = this.timeData.filter(item => {
        return item.solidifySuccess === 1
      })
      return array.length
    },
    // 固化时候的总数量 (既全量)
    opusFileAllUploadNumber() {
      return this.timeData.length
    },
    // 分片上传成功的数量
    ossSliceUploadSuccessNumber() {
      let array = this.timeData.filter(item => {
        return item.ossSliceUploadSuccess === 1 && item.isUploadFile === true
      })
      return array.length
    },
    // 分片上传时候的总数量
    ossSliceAllUploadNumber() {
      let array = this.timeData.filter(item => {
        return item.isUploadFile === true
      })
      return array.length
    },
  }
  ,

  watch: {
    stepsActive(newVal, oldVal) {
      this.btnFix = false;
    }
    ,
    opusFilePopup(val) { // 有改动
      if (!val) {
        this.opusFileProgressPopupCancelShow = false
      }
    }
    ,
    ossUploadPopup(val) { // 有改动
      if (!val) {
        this.ossUploadProgressPopupCancelShow = false
      }
    }
  }
  ,

  created() {
    this.getUserInfo();

  }
  ,

  mounted() {
    //#region
    var dropZone = document.getElementById('upFileArea');
    dropZone.addEventListener("dragenter", (e) => {
      e.preventDefault();
      e.stopPropagation();
    }, false);

    dropZone.addEventListener("dragover", (e) => {
      e.dataTransfer.dropEffect = 'copy'; // 兼容某些三方应用，如圈点
      e.preventDefault();
      e.stopPropagation();
    }, false);

    dropZone.addEventListener("dragleave", (e) => {
      e.preventDefault();
      e.stopPropagation();
    }, false);
    dropZone.addEventListener("drop", (e) => {
      let that = this;
      e.preventDefault();
      e.stopPropagation();
      let df = e.dataTransfer;

      function getDirFileList(entry) {
        if (entry.isFile) {
          entry.file((e) => {
            //console.log(e);
            let files = [e]
            that.checkFile(files)
          }, e => {
            console.log(e);
          })
        } else {
          let reader = entry.createReader();
          reader.readEntries(entries => {
            //console.log(entries);
            entries.forEach(entry => getDirFileList(entry));
          }, e => {
            console.log(e);
          })
        }
      }

      if (!this.form.fileTypeValue) {
        this.$message.error(this.applyTimeToast.selectText)
        return false
      }
      if (df.items !== undefined) {
        // Chrome拖拽文件逻辑
        for (let i = 0; i < df.items.length; i++) {
          let item = df.items[i];
          if (item.kind === "file" && item.webkitGetAsEntry().isFile) {
            let file = item.getAsFile();
            let files = [file]
            this.checkFile(files)
          }
          if (item.webkitGetAsEntry().isDirectory) {
            let directoryReader = item.webkitGetAsEntry().createReader();
            directoryReader.readEntries((entries) => {
              // console.log(entries);
              entries.forEach(entrie => getDirFileList(entrie));
            }, e => {
              console.log(e);
            })
          }
        }
      } else {
        this.$message.error(this.browserText.info3)
      }
    }, false);
    //#endregion
    this.$refs.wrapContent.addEventListener('scroll', this.getScroll);
  }
  ,

  destroyed() {
    clearInterval(interval);
  },

  filters: {
    numToFont(value,that) {
      if(that.$i18n.locale==='en'){
        return fileTypeEn(value);
      }else{
        return fileType(value);
      }

    }
  }
  ,

  methods: {
    getFileSize(size) {
      let num = 1024.00; //byte
      if (!size)
        return "";
      if (size < num)
        return size + "B";
      if (size < Math.pow(num, 2))
        return (size / num).toFixed(2) + "K"; //kb
      if (size < Math.pow(num, 3))
        return (size / Math.pow(num, 2)).toFixed(2) + "M"; //M
      if (size < Math.pow(num, 4))
        return (size / Math.pow(num, 3)).toFixed(2) + "G"; //G
      return (size / Math.pow(num, 4)).toFixed(2) + "T"; //T
    }
    ,
    delay(time) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve()
        }, time)
      })
    }
    ,
    getScroll() {
      let wrapContent = this.$refs.wrapContent;
      let scrollBottom = wrapContent.scrollHeight - wrapContent.scrollTop - wrapContent.clientHeight;
      //console.log(wrapContent.scrollHeight+'_____'+wrapContent.clientHeight)
      if (wrapContent.scrollHeight > wrapContent.clientHeight) {
        this.btnFix = true;
      } else {
        this.btnFix = false;
      }
      if (scrollBottom < 100) {
        this.btnFix = false;
      }
    }
    ,
    //******************第一步************************/
    phoneUs() {
      window.open(process.env.VUE_APP_TSA+'/about/relate.html')
    }
    ,
    //选择全局文件类型
    chooseType(value) {
      this.form.fileTypeValue = value;
    }
    ,
    //选择文件
    chooseFileOrFolder() {
      if (!this.form.fileTypeValue) {
        this.$message.error(this.applyTimeToast.selectText)
        this.$refs.inputer.value = null;
        this.$refs.folder.value = null;
        return false
      }
      this.fileOrFolder = true;
    }
    ,
    choosefile() {
      this.$refs.inputer.click();
      this.fileOrFolder = false;
    }
    ,
    choosefilesss() {
      this.$refs.folder.click();
      this.fileOrFolder = false;
    }
    ,
    //根据给定字段，去重（2字段）
    uniqueArrFile(arr, key1, key2) {
      return new Promise((resolve, reject) => {
        let cache = [];
        for (let v of arr) {
          // 检查缓存中是否已经存在
          if (cache.find(c => c[key1] === v[key1] && c[key2] === v[key2])) {
            // 已经存在说明文件重复
            reject()
            return false
          }
          cache.push(v);
        }
        resolve();
      })

    }
    ,
    addfile(n) {
      if (!this.form.fileTypeValue) {
        this.$message.error(this.applyTimeToast.selectText)
        this.$refs.inputer.value = null;
        this.$refs.folder.value = null;
        return false
      }
      let inputDOM;
      if (n == 1) {
        inputDOM = this.$refs.inputer
        let files = inputDOM.files
        console.log(files)
        this.checkFile(files)
      }
      if (n == 2) {
        inputDOM = this.$refs.folder
        this.fil = inputDOM.files;
        console.log(inputDOM.files)
        if (this.fil.length == 0) {
          return false
        }
        this.forFileList();
        this.$refs.folder.value = null;
      }
    }
    ,
    //遍历当前上传的文件，为每个文件加相关属性
    forFileList() {
      let that = this;
      let obj;
      //let newFileList=[];//接收新文件
      //let timeDataCopy= JSON.parse(JSON.stringify(this.timeData)); //拷贝一份当前文件列表list
      for (let i = 0; i < this.fil.length; i++) {
        if (Math.floor(that.fil[i].size == 0)) {
          this.$message({
            duration: 5000,
            type: 'error',
            message: `${this.applyTimeToast.kbLeftText}${that.fil[i].name}${this.applyTimeToast.kbRightText}`,
          })
        } else {
          obj = {
            fileItem: this.fil[i],
            opusName: this.fil[i].name,
            opusNameEdit: '',
            fileType: this.form.fileTypeValue,
            isUploadFile: this.form.checkedEvidence,
            applyRemark: this.form.notes,
            modified: false,
            percentage: 0,
            opusDescribe: this.opusDescribe,
            opusNameCheck: true,
            //新版增加
            id: '',
            uuid: generateUUID(),
            serialNo: '',
            solidifyPercentage: 0, // 固化上传百分比百分比
            solidifySuccess: 0, // 是否固化完成 // 0 未完成， 1 成功， 2 固化失败
            ossSliceUploadPercentage: 0, // 分片上传百分比
            ossSliceUploadSuccess: 0, // 是否分片上传完成 // 0 未完成， 1 成功， 2 上传失败
            applyFileSize: this.fil[i].size, // 文件的大小，用来进行存证扣费用的
            fileSize: this.getFileSize(this.fil[i].size)
          }
          that.timeData.push(obj);
        }


        //去重判断这版先不做
        // newFileList.push(obj);
        // if(i==(this.fil.length-1)){
        //     let array=[...timeDataCopy,...newFileList];
        //     this.uniqueArrFile(array,'opusName','size').then(()=>{
        //         that.timeData=[...that.timeData,...newFileList];
        //     }).catch((err)=>{
        //         that.$message.error('您选择的文件中有重复，请删除重复文件或修改文件名称后在上传。')
        //     })
        // }
      }

    }
    ,
    // 检测文件是否符合要求
    checkFile(files) {
      let file = files[0];
      if (file.size > 1048576) {
        this.fil = files;
        this.forFileList();
        this.$refs.inputer.value = null;
      } else {
        let reader = new FileReader()
        if (file.slice) {
          var blob = file.slice(0, file.size)
        } else if (file.webkitSlice) {
          var blob = file.webkitSlice(0, file.size)
        }
        reader.readAsArrayBuffer(blob)
        reader.onload = (e) => {
          this.fil = files
          if (this.fil.length == 0) {
            return false
          }
          if (file.size == 0) {
            this.$message({
              duration: 5000,
              type: 'error',
              message: `${this.applyTimeToast.kbLeftText}${file.name}${this.applyTimeToast.kbRightText}`,
            })
            this.$refs.inputer.value = null;
            return
          }
          this.fil = files;
          this.forFileList();
          this.$refs.inputer.value = null;
        }
        reader.onerror = (e) => {
          this.$message({
            duration: 5000,
            type: 'error',
            message: this.browserText.info4,
          })
          this.$refs.inputer.value = null;
        }
      }
    }
    ,
    //编辑文件名称。
    editFileName(index, value) {
      this.timeData[index].opusNameEdit = value;
      this.timeData[index].modified = true;
      let obj = this.timeData[index]
      this.$set(this.timeData, index, obj)
    }
    ,
    //提交编辑文件名称
    subEdit(index) {
      if (this.timeData[index].opusNameEdit.length == 0 || this.timeData[index].opusNameEdit.replace(/\s*/g, "").length == 0) {
        this.$message.error(this.applyTimeToast.emptyText)
        return false;
      }
      if (this.timeData[index].opusNameEdit.length > 100) {
        this.$message.error(this.applyTimeToast.fileNameText)
      } else {
        this.timeData[index].opusName = this.timeData[index].opusNameEdit;
        this.timeData[index].modified = false;
        this.timeData[index].opusNameCheck = true;
        let obj = this.timeData[index]
        this.$set(this.timeData, index, obj)
      }

    }
    ,
    //单选按钮选择存证方式
    chooseAuthentication(index, value) {
      this.timeData[index].isUploadFile = value;
      let obj = this.timeData[index]
      this.$set(this.timeData, index, obj)
    }
    ,
    //删除该文件内容
    remove(index) {
      this.timeData.splice(index, 1);
      this.getScroll();
    }
    ,
    //更改文件类型
    editFileType(index, row) {
      if (row.solidifySuccess == 1) {
        return false;
      }
      this.fileTypeBox = true;
      this.currentFileTypeValue = row.fileType;
      this.currentFileTypeIndex = index;
    }
    ,
    chooseBoxType(value) {
      this.currentFileTypeValue = value;
    }
    ,
    //提交弹框更改当前文件的文件类型
    subFileType() {
      let index = this.currentFileTypeIndex;
      this.timeData[index].fileType = this.currentFileTypeValue;
      let obj = this.timeData[index]
      this.$set(this.timeData, index, obj)
      this.fileTypeBox = false;
    }
    ,
    async next(num) {
      let that = this;
      if (num == 1) {
        this.stepsActive = 1;
      } else if (num == 2) {
        if (!this.form.fileTypeValue) {
          this.$message.error(this.applyTimeToast.selectText)
          return false;
        }
        if (this.timeData.length == 0) {
          this.$message.error(this.applyTimeToast.uploadText)
          return false;
        }
        let P1 = () => {
          return new Promise((resolve, reject) => {
            let count = 0;
            for (let i = 0; i < this.timeData.length; i++) {
              that.timeData[i].applyRemark = that.form.notes;//为每一项添加备注。
              if (that.timeData[i].opusName.length > 100) {
                count++;
                that.timeData[i].opusNameCheck = false;
                that.timeData[i].modified = true;
                that.timeData[i].opusNameEdit = that.timeData[i].opusName;
              }
              that.$set(that.timeData, i, that.timeData[i])
              if (i == (that.timeData.length - 1)) {
                if (count > 0) {
                  reject()
                }
                resolve();
              }
            }
          })
        }
        P1().then(async () => {
          //子账号需要获取最新状态，防止在当前申请期间主账号修改其权限信息等内容。
          if (that.$store.state.userInfo.userInfo.userType == 'AUTHORIZATION_ACCOUNT') {
            await user().then((res) => {
              if (res.forbidden) {
                that.$alert('您无权使用该服务，请与主账号管理员联系授权', this.timestampText.tipsText, {
                  confirmButtonText: this.confirmCancelToast.Confirm,
                  callback: () => {
                    window.location.href = process.env.VUE_APP_ACCOUNTUI+'?sourceType=952310'
                  }
                });
                return false
              }
              that.$store.commit('userInfo/setUserInfo', res);
            }).then(async () => {
              try {
                await that.getUserInfo();
                that.stepsActive = 2;
              } catch (err) {
                that.$message.error(err.data.message)
                return false
              }
            }).catch((err) => {
              console.log(err)
            });
          } else {
            that.stepsActive = 2;
          }
        }).catch(err => {
          that.$message.error(this.applyTimeToast.fileNameText)
          return false;
        })

      } else {
        if (this.showNameType == 2) {
          if (!this.information.applyName) {
            this.$message.error(this.applyTimeToast.applicantNameText)
            return false
          }
          if (this.information.applyName.length > 50) {
            this.$message.error(this.applyTimeToast.applicantText)
            return false
          }
        } else {
          if (!this.information.applyName) {
            this.$message.error(this.applyTimeToast.applicantNameText)
            return false
          }
          if (this.information.applyName.length > 50) {
            this.$message.error(this.applyTimeToast.applicantText)
            return false
          }
          if (!this.information.applyIdType) {
            this.$message.error(this.applyTimeToast.certificateText)
            return false
          }
          if (!this.information.applyIdNumber) {
            this.$message.error(this.applyTimeToast.idText)
            return false
          }
          if (this.information.applyIdNumber.length < 6 || this.information.applyIdNumber.length > 100) {
            this.$message.error(this.applyTimeToast.numberIncorrText)
            return false
          }
          let reg = /^[\u4e00-\u9fa5]+$/
          if (reg.test(this.information.applyIdNumber)) {
            this.$message.error(this.browserText.info5)
            return false
          }
        }
        if (!this.opusDescribe) {
          this.$message.error(this.applyTimeToast.zishuText)
          return false
        }
        let obj = {
          applyName: this.information.applyName,
          applyIdType: this.information.applyIdType,
          applyIdNumber: this.information.applyIdNumber,
        }
        if (this.$store.state.userInfo.userInfo.userType !== 'AUTHORIZATION_ACCOUNT' || this.showNameType == 2) {
          try {
            await opusUpdateUserInfo(filterParams(obj))
          } catch (err) {
            this.$message.error(err.data.message)
            return false
          }
        }
        if (this.showNameType == 2) {
          this.information = filterParams(this.information);
        }

        let paramsData = [];
        this.timeData.forEach((v, i) => {
          paramsData.push({
            keyType: 0,
            fileSize: v.applyFileSize,
            key: v.uuid,
          })
        })
        let res = await lengthCount(paramsData)
        this.tsCount = res.tsCount;
        let resultList = res.resultList;

        this.timeData = this.timeData.map((item, index) => {
          let data = resultList.find(i => item.uuid == i.key)
          return {
            ...item,
            storeCount: item.isUploadFile ? data.storeCount : 0,
            ...this.information,
            opusDescribe: that.opusDescribe
          }
        })
        console.log(this.timeData);

        // this.timeData.forEach((v, i) => {

        //     v = { ...v, ...this.information }
        //     v.opusDescribe = that.opusDescribe
        //     this.$set(this.timeData, i, v)
        // })
        this.stepsActive = num;
      }
    }
    ,
    //*******************第二步************************/
    rousedDialog() {
      this.template = true
    }
    ,
    copy() {
      var clipboard = new this.Clipboard('.copybtn')
      var that = this
      clipboard.on('success', (e) => {
        // console.log('复制成功')
        // 释放内存
        that.$message({
          message: this.applyTimeToast.copyText,
          type: 'success',
        })
        setTimeout(function () {
          that.template = false
        }, 2000)
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        console.log(this.browserText.info6)
        that.$message.error(this.browserText.info6)
        // 释放内存
        clipboard.destroy()
      })
    }
    ,
    // 获取用户信息
    async getUserInfo() {
      try {
        let res = await opusUserInfo()
        this.attestationInfo = res;
        this.showNameType = res.showNameType;
        this.information = {
          applyName: res.applyName,
          applyIdType: res.applyIdType,
          applyIdNumber: res.applyIdNumber,
        }
      } catch (error) {
        console.log(error)
        this.$message.error(this.browserText.info7)
      }
    }
    ,
    //实名跳转
    goReal() {
      window.open(process.env.VUE_APP_ACCOUNTUI + '/userCenter/AuthenticationName?sourceType=952310&partnerType=' + (this.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0))
      this.$alert(this.browserText.info8, this.browserText.info9, {
        confirmButtonText: this.confirmCancelToast.Confirm,
        callback: (action) => {
          if ((action = 'confirm')) {
            this.getUserInfo()
          }
        },
      })
    }
    ,
    //*******************第三步************************/
    // 计算所需的时间戳
    calculateTheRequiredTimestamp(callback) {
      return new Promise(async (resolve, reject) => {
        try {
          let list = []
          let TotalTimestampsCount = 0 // 总共的时间戳数量
          let solidifyCount = this.timeData.filter(item => {
            // return (item.storeType === 'DESENSITIZE_STORE' && !item.id) || (item.storeType === 'SAVE_STORE' && item.solidifySuccess !== 1)
            return item.solidifySuccess !== 1
          }).length // 需要固化的时间戳数量

          let existingEvidenceCount = 0 // 存证的时间戳数量
          this.timeData.map(item => {
            if (item.isUploadFile && !item.id) {
              list.push({
                "key": item.uuid,
                "fileSize": item.applyFileSize,
                "keyType": 0
              })
            }
          })
          let {tsCount, resultList} = await lengthCount(list)
          // 计算固化所需要的时间戳
          solidifyCount = parseInt((tsCount * solidifyCount).toFixed(0))
          // 计算存证所需要的时间戳
          resultList && resultList.map(item => {
            existingEvidenceCount = parseInt((existingEvidenceCount + item.storeCount).toFixed(0)) // 每一次最好取证计算
          })
          // 计算时间戳总数
          TotalTimestampsCount = parseInt((solidifyCount + existingEvidenceCount).toFixed(0))
          resolve(TotalTimestampsCount)
        } catch (error) {
          reject(error)
          callback && callback()

        }
      })
    }
    ,
    //计算当前应扣减的时间戳数量，用于判断余额是否够用
    getAccountNum() {
      let TotalTimestampsCount = 0 // 总共的时间戳数量
      let solidifyCount = this.timeData.length // 固化的时间戳数量
      let existingEvidenceCount = 0 // 存证的时间戳数量
      let solidifySuccessCount = 0 //已固化完成的时间戳数量
      let existingEvidenceSuccessCount = 0 //已存证完成的时间戳数量
      // 计算固化所需要的时间戳
      solidifyCount = parseInt((this.tsCount * solidifyCount).toFixed(0))
      // 计算存证所需要的时间戳
      this.timeData.map(item => {
        if (item.solidifySuccess == 1) {
          solidifySuccessCount = parseInt((solidifySuccessCount + 1).toFixed(0))
        }
        if (item.ossSliceUploadSuccess == 1) {
          existingEvidenceSuccessCount = parseInt((existingEvidenceSuccessCount + item.storeCount).toFixed(0))
        }
        existingEvidenceCount = parseInt((existingEvidenceCount + item.storeCount).toFixed(0)) // 每一次最好取证计算
      })
      // 计算时间戳总数
      TotalTimestampsCount = parseInt((solidifyCount + existingEvidenceCount).toFixed(0))
      //计算应该扣费的时间戳总数
      let deductTimestampsCount = TotalTimestampsCount - existingEvidenceSuccessCount - solidifySuccessCount
      return deductTimestampsCount
    }
    ,
    async submit() {
      let that = this
      try {
        let res = await findBalance(this.getAccountNum());
        if (res.deductible == 1) {  //余额够扣给出提示继续下载
          await this.fileStart({solidifyType: 1}) //第一个弹框脱敏认证
          await this.existingEvidence({existingEvidenceType: 1}) // 存证 分片上传
          let balance = await findBalance(0)
          that.$store.commit('balance/setBalance', balance)
          sessionStorage.setItem('serialNos', JSON.stringify(this.serialNos));
          if (this.serialNos.length > 0) {
            this.$router.replace({
              path: '/time/ApplyResult',
            })
          } else {
            this.stepsActive = 1;
          }

        } else if (res.deductible == 4) { //身份主账号，余额不足
          this.TotalTimestampsCount = await this.calculateTheRequiredTimestamp()
          this.$confirm(this.timeStampArrearageMessage(this.balanceTextToast.beforeUseText), this.timestampText.tipsText, {
            dangerouslyUseHTMLString: true,
            distinguishCancelAndClose: true,
            confirmButtonText: this.timestampText.rechargeText,
            cancelButtonText: this.timestampText.rechargedText,
            type: 'warning',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                window.open(process.env.VUE_APP_ACCOUNTUI + '/userAccount/buy?sourceType=952310&partnerType=' + (this.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0))
              } else {
                done()
              }
            },
          }).then(() => {
            console.log('去充值吧')
          }).catch(async () => {
            console.log('取消')
            let balance = await findBalance(0)
            that.$store.commit('balance/setBalance', balance)
          })
        } else if (res.deductible == 2) { //身份子账号，主账号余额不足
          this.TotalTimestampsCount = await this.calculateTheRequiredTimestamp()
          this.$alert(this.timeStampArrearageMessage(this.balanceTextToast.rechargeText), this.timestampText.tipsText, {
            confirmButtonText: this.confirmCancelToast.Confirm,
            dangerouslyUseHTMLString: true,
          });
          //that.$message.error(res.errorMessage)
        } else { //deductible=3 身份子账号，主账号分配次数达上限
          that.$message.error(this.balanceTextToast.allocateText)
        }
      } catch (error) {
        console.log(error)
        if (error.name && error.name == 'cancel') {
          console.log('取消存证')
        } else if(error.data.code==300026){
          this.$message.error(error.data.message)
        }else {
          this.$message.error(this.browserText.info7)
        }
      }
    }
    ,
    // 1.开启文件固化程序先脱敏扣费弹框 solidifyType 正常固化值为 1， 点击结束固化弹窗中的取消按钮，执行的接续固化 值为 2
    async fileStart({solidifyType}) {
      return new Promise(async (resolve, reject) => {
        let obj;
        let hasUpload;//list中是否有存证的项
        try {
          if (this.opusFileUploadSuccessNumber === this.opusFileAllUploadNumber) {
            clearInterval(interval) // 结束保活
            interval = null // 结束保活
            this.opusFilePopup = false // 关闭弹窗
            resolve()
            return
          }
          // 进行保活处理
          interval = setInterval(() => {
            me().then((res) => {
              console.log('正在保活')
            }).catch((err) => {
              console.log(err)
            });
          }, 60000);
          this.allowSolidification = true
          if (solidifyType === 1) {
            this.timeData.map(item => {
              if (item.solidifySuccess !== 1) {
                item.solidifyPercentage = 0
                item.solidifySuccess = 0
              }
            })
          }
          this.opusFilePopup = true
          await this.delay(0) // 用来代替 nextTick
          this.$refs.solidifyProgressPopUp.initScroll()
          for (let i = 0; i < this.timeData.length; i++) {
            obj = this.timeData[i];
            //为首都知产而加字段partnerType=1代表首都知产用户
            obj.partnerType = this.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0;
            this.$refs.solidifyProgressPopUp.autoScroll(obj.uuid)
            if ((solidifyType === 1 && obj.solidifySuccess !== 1) || (solidifyType === 2 && obj.solidifySuccess === 0)) { // 如果是再次固化的需要去除 固化失败的
              this.currentIndex = i // 设置当前索引
              let {isUploadFile} = obj
              let reader;
              let fileHash = await hashFile(obj.fileItem, 'SHA256', (percentage) => {
                if (this.allowSolidification === false) { // 固化结束
                  reader && reader.abort()
                  reader = null
                  return
                }
                obj.solidifyPercentage = percentage
              }, ({reader}) => {
                reader = reader
                if (this.allowSolidification === false) { // 固化结束
                  reader && reader.abort()
                  reader = null
                  return
                }
              }, 1)
              if (fileHash === null) {
                obj.solidifySuccess = 3;
              } else {
                obj.fileHash = fileHash;
                // console.log('第一步的hash', sha256)
                if (this.allowSolidification === false) return
                let res = await opusApply(obj)
                let {code, id, message, serialNo} = res;
                if (code === 9523100) { //固化成功
                  // obj.md5 = CryptoJs.enc.Base64.stringify(md5)
                  obj.serialNo = serialNo
                  this.serialNos.push(serialNo);
                  if (!isUploadFile) { // 如果是脱敏的 认为是固化成功
                    obj.id = id
                  } else {
                    hasUpload = true;
                  }
                  obj.solidifySuccess = 1
                  this.timeData[i] = obj
                  this.$set(this.timeData, i, obj)
                  let balance = await findBalance(0)
                  this.$store.commit('balance/setBalance', balance)
                } else {
                  if (this.solidifyFail(code, obj, message)) {
                    return
                  }
                }
              }
              //如果没有存证的，那么只有固化一个弹框，判断当前是最后一个并且已完成固化，直接跳成功页。
              if (this.opusFileProgressPopupCancelShow === true && (i == this.timeData.length - 1) && obj.solidifySuccess == 1 && !hasUpload) {
                sessionStorage.setItem('serialNos', JSON.stringify(this.serialNos));
                this.$router.replace({
                  path: '/time/ApplyResult',
                })
                return
              }
              // 如果弹出了取消固化的弹窗，就自动停止，不进入下一个循环
              if (this.opusFileProgressPopupCancelShow === true) return
            }
          }
          await this.delay(1000) // 如果此时结束了固化
          console.log('固化结束')
          clearInterval(interval) // 结束保活
          interval = null // 结束保活
          for (let i = 0; i < this.timeData.length; i++) {
            if (this.timeData[i].solidifySuccess === 3) {
              this.timeData.splice(i, 1)
              i--
            }
          }
          if (this.opusFileUploadSuccessNumber === this.opusFileAllUploadNumber) {
            this.opusFilePopup = false // 关闭弹窗
            console.log('****************')
            // if (this.allowSolidification === true) { // 允许固化的时候固化
            //     callback && callback()
            // }
            resolve()
          } else {
            console.log('------solidify-----has---fail---')
            reject()
          }
        } catch (error) {
          console.log(error)
          console.log('------solidify-----error---')
          obj.solidifySuccess = 2
          reject(error)
        }
      })
    }
    ,
    async solidifyFail(code, obj, message) {
      obj.solidifySuccess = 2;
      if (code === 9523101) { // 固化失败了
        //obj.solidifySuccess = 2
        return false
      } else if (code === 10001) {
        this.TotalTimestampsCount = await this.calculateTheRequiredTimestamp()
        if (this.userInfo.userType === 'ADMIN_ACCOUNT') { // 主账号
          this.$confirm(this.timeStampArrearageMessage(message), this.timestampText.tipsText, {
            dangerouslyUseHTMLString: true,
            distinguishCancelAndClose: true,
            confirmButtonText: this.timestampText.rechargeText,
            cancelButtonText: this.timestampText.rechargedText,
            type: 'warning',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                window.open(
                    process.env.VUE_APP_ACCOUNTUI + '/userAccount/buy?sourceType=952310&partnerType=' + (this.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0)
                )
              } else if (action === 'close') {
                done()
              } else {
                done()
              }
            },
          }).then(() => {
            console.log('去充值吧')
          }).catch(async () => {
            console.log('取消')
          })
          this.opusFilePopup = false
          this.opusFileProgressPopupCancelShow = false
          return true
        } else {
          this.$alert(this.timeStampArrearageMessage(message), this.timestampText.tipsText, {
            confirmButtonText: this.confirmCancelToast.Confirm,
            dangerouslyUseHTMLString: true,
          });
          this.opusFilePopup = false
          this.opusFileProgressPopupCancelShow = false
          return true
        }
      } else {
        this.TotalTimestampsCount = await this.calculateTheRequiredTimestamp()
        // 10013 子账号超出最大限额
        this.$alert(this.timeStampArrearageMessage(message), this.timestampText.tipsText, {
          confirmButtonText: this.confirmCancelToast.Confirm,
          dangerouslyUseHTMLString: true,
        });
        this.opusFilePopup = false
        this.opusFileProgressPopupCancelShow = false
        return true
      }
    }
    ,
    //固化过程中，点击取消显示的弹窗
    opusFilePopupStop() {
      this.opusFileProgressPopupCancelShow = true
    }
    ,
    //固化过程中，点击取消
    async opusFileProgressPopupCancel() {
      let currentObj = this.timeData[this.currentIndex]
      // 如果结束固化的过程中存在 hash算完并且 opusApply 还没处理完的情况下
      if (currentObj.solidifyPercentage === 100 && currentObj.solidifySuccess === 0) {
        this.$message({
          message: this.browserText.info10,
          type: 'warning'
        });
      } else {
        this.opusFileProgressPopupCancelShow = false // 关闭弹窗 （必须先关闭）
        // 如果点击取消的时候，流程没有进入固化 代码继续往下走，如果已经进入了固化接口，那么执行下面的函数，重新执行固化函数，继续固化
        if (currentObj.solidifySuccess !== 0) {
          await this.fileStart({solidifyType: 2})
          await this.existingEvidence({existingEvidenceType: 2})
        }
      }
    }
    ,
    //固化过程中，点击确定结束固化
    opusFileProgressPopupConfirm() {
      let currentObj = this.timeData[this.currentIndex]
      // 如果结束固化的过程中存在 hash算完并且 opusApply 还没处理完的情况下
      if (currentObj.solidifyPercentage === 100 && currentObj.solidifySuccess === 0) {
        this.$message({
          message: this.browserText.info10,
          type: 'warning'
        });
      } else {
        clearInterval(interval) // 结束保活
        interval = null // 结束保活
        this.$store.commit('axiosCancel/clearAxiosPromiseCancelArr')
        this.allowSolidification = false
        this.opusFilePopup = false
        this.opusFileProgressPopupCancelShow = false
      }
    }
    ,
    // 2.全部脱敏扣费后再继续存证上传扣费
    async existingEvidence({existingEvidenceType}) {
      return new Promise(async (resolve, reject) => {
        console.log(this.ossSliceUploadSuccessNumber + '----' + this.ossSliceAllUploadNumber)
        if (this.ossSliceUploadSuccessNumber === this.ossSliceAllUploadNumber) {
          clearInterval(interval) // 结束保活
          interval = null // 结束保活
          this.ossUploadPopup = false // 有改动
          resolve()
          return
        }
        let obj;
        // 需要判断钱是否够
        try {
          // 进行保活处理
          interval = setInterval(() => {
            me().then((res) => {
              console.log('正在保活')
            }).catch((err) => {
              console.log(err)
            });
          }, 60000);
          this.allowExistingEvidence = true
          if (existingEvidenceType === 1) {
            this.timeData.map(item => {
              if (item.ossSliceUploadSuccess !== 1 && item.isUploadFile) {
                item.ossSliceUploadPercentage = 0
                item.ossSliceUploadSuccess = 0
              }
            })
          }
          this.ossUploadPopup = true
          await this.delay(0) // 用来代替 nextTick
          this.$refs.ossSliceUploadProgressPopUp.initScroll()
          for (let i = 0; i < this.timeData.length; i++) {
            obj = this.timeData[i]
            if (obj.isUploadFile && ((existingEvidenceType === 1 && !obj.id) || (existingEvidenceType === 2 && obj.ossSliceUploadSuccess === 0))) {
              this.currentIndex = i
              this.$refs.ossSliceUploadProgressPopUp.autoScroll(obj.uuid)
              let {fileHash, serialNo, fileItem, applyFileSize} = obj
              if (this.allowExistingEvidence === false) return
              let {
                accessKey,
                secretKey,
                secretToken,
                bucket,
                key,
                storeId
              } = await generateStsUploadSignMessage(encrypt({
                serialNo,
                applyFileSize,
                partnerType:this.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0
              }), 'nqio8Mem5oDNNBxOStIAlIsxrtWAx/c8')
              obj.storeId = storeId
              if (this.allowExistingEvidence === false) return
              await ossMultipartUpload({
                fileHash,
                accessKey,
                secretKey,
                secretToken,
                bucket,
                key,
                file: fileItem,
              }, ({percentage}) => {
                obj.ossSliceUploadPercentage = percentage
              })
              if (this.allowExistingEvidence === false) return
              let {id} = await this.ossSliceUploadGetStatus({serialNo, storeId})
              if (id) {
                obj.ossSliceUploadSuccess = 1 // 上传成功
                obj.id = id
              } else {
                obj.ossSliceUploadSuccess = 2 // 上传失败
              }
              this.timeData[i] = obj;
              this.$set(this.timeData, i, obj)
              if (this.ossUploadProgressPopupCancelShow === true && (i == this.timeData.length - 1) && obj.ossSliceUploadSuccess == 1) {//再点取消的时候，最后一个已经存证完成，那就直接跳转到成功页
                sessionStorage.setItem('serialNos', JSON.stringify(this.serialNos));
                this.$router.replace({
                  path: '/time/ApplyResult',
                })
                return
              }
              if (this.ossUploadProgressPopupCancelShow === true) return
            }
          }
          await this.delay(1000) // 1秒后结束固化
          clearInterval(interval) // 结束保活
          interval = null // 结束保活
          if (this.ossSliceUploadSuccessNumber === this.ossSliceAllUploadNumber) {
            this.ossUploadPopup = false
            resolve()
          } else {
            reject()
          }
        } catch (error) {
          if (error && error.config) {
            let {code, message} = error.data
            if (ExcludeRequest400.indexOf(error.config.url) > -1) {
              // 存证失败的情况
              this.existingEvidenceFail(code, obj, message)
              return
            }
          }
          reject(error)
        }
      })
    }
    ,
    // 查询最新的存证状态
    ossSliceUploadGetStatus({serialNo, storeId}, onOff) {
      console.log(serialNo);
      return new Promise((resolve, reject) => {
        let time = null;
        clearTimeout(time)
        const getStatus = ({serialNo, storeId}) => {
          if (this.allowExistingEvidence === false && !onOff) {
            resolve({})
            return
          }
          completeStatus({serialNo, storeId}).then(async res => {
            if (this.allowExistingEvidence === true || onOff) {
              if (res.sourceFileUploadType === 0) { // 状态没有更新，继续轮询
                time = setTimeout(() => {
                  getStatus({serialNo, storeId})
                }, 1000)
              } else {
                resolve(res)
                clearTimeout(time)
                time = null
                let balance = await findBalance(0)
                this.$store.commit('balance/setBalance', balance)
              }
            } else {
              resolve({})
              clearTimeout(time)
              time = null
            }
          }).catch((error) => {
            resolve({})
            clearTimeout(time)
            time = null
          })
        }
        getStatus({serialNo, storeId})
      })
    }
    ,
    timeStampArrearageMessage(message) {
      return '<div class="timeStampArrearage"><h4>' + this.balanceTextToast.consumeTextLeft1 + this.TotalTimestampsCount + this.timestampText.timestamp + '</h4>' + message + '</div>'
    }
    ,
    // 存证失败
    async existingEvidenceFail(code, obj, message) {
      // code 2 您的主账号余额不足，请联系主账号充值
      // code 3 您的使用个数已达上限，请联系主账号分配
      // code 4 您的余额不足，请充值后在使用
      message = message || this.browserText.info7
      this.ossUploadPopup = false // 关闭存证进度弹窗
      this.ossUploadProgressPopupCancelShow = false // 关闭结束存证的弹窗（会出现，几率很小）
      obj.ossSliceUploadSuccess = 2
      clearInterval(interval) // 清空保活接口
      interval = null
      this.TotalTimestampsCount = await this.calculateTheRequiredTimestamp()
      switch (code) {
        case 2:
          this.$alert(this.timeStampArrearageMessage(message), this.timestampText.tipsText, {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.confirmCancelToast.Confirm,
          });
          break
        case 3:
          this.$alert(this.timeStampArrearageMessage(message), this.timestampText.tipsText, {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.confirmCancelToast.Confirm,
          });
          break
        case 4:
          this.$confirm(this.timeStampArrearageMessage(message), this.timestampText.tipsText, {
            dangerouslyUseHTMLString: true,
            distinguishCancelAndClose: true,
            confirmButtonText: this.timestampText.rechargeText,
            cancelButtonText: this.timestampText.rechargedText,
            type: 'warning',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                window.open(
                    process.env.VUE_APP_ACCOUNTUI + '/userAccount/buy?sourceType=952310&partnerType=' + (this.$getlocalStorage('queryData')?.partnerType == 1 ? 1 : 0)
                )
              } else if (action === 'close') {
                done()
              } else {
                done()
              }
            },
          }).then(() => {
            console.log('去充值吧')
          }).catch(async () => {
            console.log('取消')
          })
          break
        default:
          this.$alert(message, this.timestampText.tipsText, {
            confirmButtonText: this.confirmCancelToast.Confirm,
          });
      }
    }
    ,
    //存证过程中，点击取消显示的弹窗
    ossUploadPopupStop() {
      this.ossUploadProgressPopupCancelShow = true
    }
    ,
    //存证过程中，点击结束存证
    async ossUploadProgressPopupConfirm() {
      try {
        this.ossUploadProgressPopupCancelDisabled = true // 禁止重复点击
        let currentObj = this.timeData[this.currentIndex]
        if (currentObj.isUploadFile && currentObj.ossSliceUploadPercentage === 100 && currentObj.ossSliceUploadSuccess === 0) {
          this.$message({
            message: this.browserText.info11,
            type: 'warning'
          });
        } else {
          clearInterval(interval) // 清空保活接口
          interval = null
          // 这里可能还需要清除一个定时器
          this.allowExistingEvidence = false // 存证不允许
          // 如果上传成功的都不允许删除
          if (currentObj.ossSliceUploadPercentage < 100) {
            // 需要调用终止分片的接口 （已经上传到服务器的就不能被取消了）
            await cancelStsUpload({
              serialNo: currentObj.serialNo,
              storeId: currentObj.storeId,
            })
            await ossAbortMultipartUpload() // 取消oss请求
          }
          this.$store.commit('axiosCancel/clearAxiosPromiseCancelArr')
          this.ossUploadPopup = false
          this.ossUploadProgressPopupCancelShow = false
        }
        this.ossUploadProgressPopupCancelDisabled = false // 禁止重复点击
      } catch (error) {
        if (error && error.status === 400 && error.data && error.data.code == -1 && error.config && error.config.url === '/opus/cancelStsUpload') {
          this.allowExistingEvidence = true
          let {id} = await this.ossSliceUploadGetStatus({serialNo, storeId}, true)
          if (id) {
            currentObj.ossSliceUploadSuccess = 1 // 上传成功
            currentObj.id = id
            currentObj.ossSliceUploadPercentage = 100 // 成功
          }
          await this.existingEvidence({existingEvidenceType: 2})
        } else {
          await ossAbortMultipartUpload() // 取消oss请求
          clearInterval(interval) // 清空保活接口
          interval = null
          this.allowExistingEvidence = false // 存证不允许
          this.ossUploadProgressPopupCancelDisabled = false // 禁止重复点击
          this.$store.commit('axiosCancel/clearAxiosPromiseCancelArr')
          this.ossUploadPopup = false
          this.ossUploadProgressPopupCancelShow = false
        }
      }
    },
    async ossUploadProgressPopupCancel() {
      let currentObj = this.timeData[this.currentIndex]
      if (currentObj.isUploadFile && currentObj.ossSliceUploadPercentage === 100 && currentObj.ossSliceUploadSuccess === 0) {
        this.$message({
          message: this.browserText.info11,
          type: 'warning'
        });
      } else {
        this.ossUploadProgressPopupCancelShow = false // 关闭弹窗 （必须先关闭）
        // 如果点击取消的时候，流程没有进入存证 代码继续往下走，如果已经进入了存证接口，那么执行下面的函数，重新执行固化、、存证函数，继续存证
        if (currentObj.isUploadFile && currentObj.ossSliceUploadSuccess !== 0) {
          await this.existingEvidence({existingEvidenceType: 2})
        }
      }
    },
  }
}
;
</script>
<style src="../../assets/css/applyTime.less" scoped lang="less"></style>
<style scoped lang="less">
.ProgressPopupCancel {
  :deep(.content) {
    padding: 0;
    padding-bottom: 10px;

    .area {
      padding-bottom: 10px;
    }

    .btnGroup {
      padding-right: 16px;
    }
  }

  .ProgressPopupCancel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #F0F0F0;

    .title-container {
      width: 100%;
      display: flex;
      padding: 16px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #F0F0F0;

      h1 {
        font-size: 16px;
        color: #333;
        font-weight: bold;
        margin-bottom: 0;
      }

      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }

    .el-icon-warning {
      display: block;
      font-size: 50px;
      color: rgb(244, 125, 6);
      margin-top: 47px;
      margin-bottom: 20px;
    }

    h2 {
      text-align: center;
      color: #333333;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 52px;
    }
  }

}

.timeStampArrearage {
  text-align: center;
}
</style>
