<template>
    <div class="tsa-ProgressPopUp" v-show="show">
        <div class="main-container" :style="{width: width ? width + 'px' : '840px'}">
            <div class="title">
                <h2>{{ title }}</h2>
                <span class="el-icon-close" @click="stop"></span>
            </div>
            <div style="height: 30px"></div>
            <div class="content-container">
                <div class="progressContent" ref="opusFilePopupId" v-if="show">
                    <div v-for="(item, index) in listData" :key="item.uuid" :ref="'DOM' + item.uuid" :id="'DOM' + item.uuid">
                        <!-- 如果id不存在并且是本地上传的时候 -->
                        <div class="item" v-if="!isUpFile || item.isUploadFile">
                            <div class="infor">
                                <p class="ellipsis">{{ item.opusName }}</p>
                                <b>{{ item[percentage] }}%</b>
                            </div>
                            <div class="container">
                                <div class="progress-container">
                                    <el-progress :stroke-width="8" :show-text="false" :percentage="item[percentage]" :color="colorFormat(item)"></el-progress>
                                </div>
                                <div class="word" v-show="item[success] === 0 && item[percentage] === 0">
                                    <span class="el-icon-time"></span>
                                    <p class="status">{{ fileStatus['waiting'] }}</p>
                                </div>
                                <div class="word" v-show="item[success] === 0 && item[percentage] <= 100 && item[percentage] > 0">
                                    <span class="el-icon-loading" style="color: rgb(32, 158, 245)"></span>
                                    <p class="status">{{ fileStatus['processing'] }}</p>
                                </div>
                                <div class="word" v-show="item[success] === 1">
                                    <span class="el-icon-success"></span>
                                    <p class="status">{{ fileStatus['success'] }}</p>
                                </div>
                                <div class="word" v-show="item[success] === 2">
                                    <span class="el-icon-error"></span>
                                    <p class="status">{{ fileStatus['fail'] }}</p>
                                </div>
                                <div class="word" v-show="item[success] === 3">
                                    <span class="el-icon-error"></span>
                                    <p class="status">{{ fileStatus['fileDamage'] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn">
                    <div class="fileNumber">
                        <p>{{fileStatus.showResult}}</p>
                        <b>{{ uploadSuccessNumber }}</b>
                        <span>/</span>
                        <p>{{ AllUploadNumber }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mask"></div>
    </div>
</template>
<script>
    export default {
        props: {
            listData: {
                type: Array,
                default: () => {
                    return []
                }
            },
            value: {  //父组件使用v-model 默认vue会给子组件传一个名称为value的props
                type: Boolean,
                default: false
            },
            width: {
                type: Number,
                default: 0
            },
            showField: {
                type: Object,
                default: () => {}
            },
            title: {
                type: String,
                default: '标题'
            },
            percentage: {
                type: String,
                default: 'percentage'
            },
            success: {
                type: String,
                default: 'success'
            },
            uploadSuccessNumber: {
                type: Number,
                default: 0
            },
            AllUploadNumber: {
                type: Number,
                default: 0
            },
            isUpFile:{
                type: Boolean,
                default: false
            },
            fileStatus: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data () {
            return {
                show: false,
                opusFilePopupIdClientHeight: 0
            }
        },
        watch: {
            value (val) {
                this.show = val;
            },
        },
        methods: {
            showFieldFun (item) {
                console.log(item)
                let keyArray = Object.keys(this.showField)
                let matchNumber = 0
                if (keyArray.length === 0) {
                    return true
                } else {
                    for (let i = 0; i < keyArray.length; i++) {
                        let key = keyArray[i]
                        if (this.showField[key] === item[key]) {
                            matchNumber = matchNumber + 1
                        }
                    }
                    if (keyArray.length === matchNumber) {
                        return true
                    } else {
                        return false
                    }
                }
            },
            stop () {
                this.$emit('on-stop')
            },
            colorFormat (item) {
                let color;
                switch (item[this.success]) {
                    case 0:
                        color = '#1890FF'
                        break
                    case 1:
                        color = '#7FC337'
                        break
                    case 2:
                        color = '#FF4D4F'
                        break
                }
                return color
            },
            initScroll () {
                let opusFilePopupId = this.$refs.opusFilePopupId
                this.opusFilePopupIdClientHeight = opusFilePopupId.clientHeight
                opusFilePopupId.scrollTop = 0
            },
            autoScroll (uuid) {
                let dom = document.getElementById('DOM' + uuid)
                if (dom.offsetTop > 400) { // 出现滚动条
                    this.$refs.opusFilePopupId.scrollTop = dom.offsetTop - this.opusFilePopupIdClientHeight + dom.clientHeight
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .tsa-ProgressPopUp {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999;
        display: flex;
        justify-content: center;
        align-items: center;
        .main-container {
            position: relative;
            background: #fff;
            z-index: 2;
            .title {
                height: 54px;
                line-height: 54px;
                background: #6288fb;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                h2 {
                    color: #fff;
                    font-size: 18px;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 0;
                }
                span {
                    position: absolute;
                    font-size: 16px;
                    color: #fff;
                    right: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                }
            }
            .content-container {
                padding: 0 20px;
                padding-bottom: 20px;
                padding-right: 6px;
                .progressContent {
                    position: relative;
                    padding: 0;
                    box-sizing: border-box;
                    max-height: 408px;
                    // max-height: 200px;
                    overflow-y: auto;
                    // &::-webkit-scrollbar {
                    //     width: 10px; /* 纵向滚动条的大小 */
                    //     height: 10px; /* 横向滚动条的大小 */
                    // }
                    // /*定义滚动条轨道 内阴影+圆角*/
                    // &::-webkit-scrollbar-track {
                    //     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    //     border-radius: 5px;
                    //     background: rgba(0, 0, 0, 0.1);
                    // }
                    // // /*定义滑块 内阴影+圆角*/
                    // &::-webkit-scrollbar-thumb {
                    //     border-radius: 5px;
                    //     -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    //     background: rgba(0, 0, 0, 0.15);
                    // }
                    .item {
                        padding-bottom: 20px;
                        .infor {
                            margin-bottom: 6px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 450px;
                            p {
                                color: #606266;
                                font-size: 14px;
                                margin-bottom: 0;
                            }
                            b {
                                color: #606266;
                                font-size: 14px;
                            }
                            .ellipsis {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                        .container {
                            display: flex;
                            align-items: center;
                            .progress-container {
                                margin-right: 15px;
                                // flex: 1;
                                width: 450px;
                            }
                            .word {
                                display: flex;
                                align-items: center;
                                span {
                                    font-size: 20px;
                                    margin-right: 15px;
                                }
                                .el-icon-success {
                                    color: #7FC337;
                                }
                                .el-icon-error {
                                    color: #FF4D4F
                                }
                                img {
                                    width: 16px;
                                    height: 16px;
                                    margin-right: 8px;
                                }
                                .status {
                                    color: #666666;
                                    font-size: 14px;
                                    margin-bottom: 0;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
            
            .fileNumber {
                display: flex;
                align-items: flex-end;
                height: 32px;
                p {
                    color: #666;
                    font-size: 14px;
                    margin-bottom: 0;
                }
                b {
                    color:#6288fb;
                    font-size: 20px;
                    margin-left: 30px;
                    margin-right: 4px;
                    line-height: normal;
                }
                span {
                    color: #666;
                    font-size: 14px;
                    margin-right: 4px;
                }
            }
            .btn {
                padding-right: 20px;
                padding-top: 20px;
            }
        }
        .mask {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba($color: #000000, $alpha: 0.7);
        }
    }
</style>